import React from 'react';
import { graphql } from 'gatsby';
import WebinarsPage from 'components/pages/WebinarsPage';

const seo = {
  title: 'Consulta todas las repeticiones y los próximos seminarios web de Q°emotion!',
  keywords: [],
  description:
    '¿Quiere saber más sobre el análisis semántico emocional de sus verbatims y reseñas de clientes? Descubra los seminarios web preparados por nuestros expertos. Suscríbase a los próximos o vea nuestra repetición.',
};

export default props => <WebinarsPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataIntroBlockTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "webinars" }
        blockType: { eq: "introBlock" }
        component: { eq: "title" }
        lang: { eq: "es" }
      }
    ) {
      html
    }
    allImages: allFile(filter: { relativeDirectory: { eq: "bubbleImages" } }) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    allWebinars: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "webinars" }
          blockType: { eq: "webinarBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          id
          duration
        }
        html
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
